@use '../../Util/Styles/Mixins.scss';

.ad-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 0 12px 20px;

  .header {
    height: 80px;
    width: 100%;
    padding-left: 30px;
    display: flex;
    align-items: center;

    background-color: white;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 20px;

    .header__search {
      height: 40px;
      width: 50%;

      border: 1px solid #E2E8F0;
      padding-left: 40px;
      border-radius: 4px;
      outline: none;
      background-repeat: no-repeat;
      background-position: 15px center;
      background-image: url('../../Util/Images/search-icon.svg');

      transition: 300ms;

      @include Mixins.default-font-picker(16px,400,#3D3D3D);

      &::-webkit-search-cancel-button {
        position:relative;
        right: 5px;

        -webkit-appearance: none;
        height: 15px;
        width: 15px;

        background-image: url('../../Util/Images/cross.svg');
        background-repeat: no-repeat;
      }
    }
  }

  .list {
    width: 100%;
    flex: 0 1 auto;

    &__header {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding: 34px 38px 30px;
      background-color: white;
      border-radius: 5px;
      background-position-y: center;

      transition: 300ms;

      .title {
        @include Mixins.default-font-picker(20px,400,#3D3D3D);
        transform: translateY(-3px);
        margin-right: 20px;
      }

      .country-name {
        @include Mixins.default-font-picker(20px,400,#3D3D3D);
        transform: translateY(-3px);
      }
    }

    &__header--button {
      cursor: pointer;
      padding-left: 50px;

      background-repeat: no-repeat;
      background-image: url('../../Util/Images/arrow-left.svg');
      background-position-y: center;
      background-position-x: 25px;

      transition: 300ms;
    }

    .grid {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 73vh;

      .grid-list {
        display: grid;
        grid-template-columns: repeat(2 ,1fr);
        grid-auto-rows: 81px;
        row-gap: 22px;
        column-gap: 13px;
        
        height: 95%;
        width: 100%;
  
        overflow-y: auto;
  
        &::-webkit-scrollbar {
          width: 5px;
          background-color: white;
          border-radius: 5px;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #F2994A;
          border-radius: 5px;
        }
  
        .grid-item {
          display: flex;
          align-items: center;
          padding-left: 31px;
          height: 81px;
          width: 100%;

          background-repeat: no-repeat;
          background-position-y: center;
          background-position-x: 95%;
          background-image: url('../../Util/Images/arrow-right(grey).svg');
  
          background-color: white;
          border-radius: 5px;
          @include Mixins.default-font-picker(16px,400,#4F4F4F);

          cursor: pointer;

          .flag {
            width: 50px;
            height: 50px;

            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: 50%;

            margin-right: 20px;
          }
        }
      }
    }

    .places-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 73vh;

      overflow-y: auto;
  
      &::-webkit-scrollbar {
        width: 5px;
        background-color: white;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #F2994A;
        border-radius: 5px;
      }

      .place {
        display: grid;
        grid-template-columns: repeat(5,1fr) 20px;
        padding: 0 30px 0 40px;
        width: 100%;
        height: 141px;
        flex-shrink: 0;

        transition: 300ms;

        @media (max-width: 1120px) {
          padding: 0 5px;
          grid-template-columns: 1fr 1fr 85px 1fr 1fr 25px;
        }

        background-color: white;
        box-shadow: 0px 6px 4px rgba(222, 219, 219, 0.25);
        border-radius: 5px;
        margin-bottom: 16px;

        .main-info , .logo, .link , .description, .color {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
        }

        .title {
          @include Mixins.default-font-picker(13px,400,#97ADB6);
          margin-bottom: 10px;
        }

        .main-info {
          .place-name {
            display: flex;
            align-items: center;
            @include Mixins.default-font-picker(20px,600,#000000);

            .status {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: #EB5757;
              margin-left: 10px;
            }

            .status.is_active {
              background-color: #27AE60;
            }
          }

          .date {
            @include Mixins.default-font-picker(12px,400,#4F4F4F);
          }
        }

        .logo {
          .logo__image {
            width: 111px;
            height: 50px;
            background-color: #C4C4C4;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
        }

        .link {
          &__text {
            @include Mixins.default-font-picker(15px,400,#E7833B);
          }
        }

        .edit-button {
          .button {
            margin-top: 20px;
            width: 25px;
            height: 25px;
            cursor: pointer;
            background-repeat: no-repeat;
            background-image: url('../../Util/Images/editpen-icon.svg');
          }
        }
      }
    }
  }
}