@use '../../Util/Styles/Mixins.scss';

.pagination-container {
  display: flex;
  list-style-type: none;
  position: relative;
  z-index: 1;


  .pagination-item {
    box-sizing: border-box;
    width: 9px;
    padding: 0 12px;
    height: 32px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
    @include Mixins.default-font-picker(14px,400,#778B94);

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      border-bottom: 2px solid #E7833B;
      cursor: pointer;
    }

    &.selected {
      border-bottom: 2px solid #E7833B;
    }
    
    .arrow {
      &::before {
        position: static;
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
      }

      &.left {
        background-image: url('../../Util/Images/arrow-left.svg');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      
      &.right {
        background-image: url('../../Util/Images/arrow-right.svg');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100% 100%;
      }
    }

    &.disabled {
      pointer-events: none;
      position: static;

      .arrow::before {

        &.left {
          background-image: url('../../Util/Images/arrow-left.svg');
        }

        &.right {
          background-image: url('../../Util/Images/arrow-right.svg');
        }
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}