@use '../../../Util/Styles/Mixins.scss';

.blur__background-form {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: black;
  opacity: 0.15;
  transform: translateZ(4);
  z-index: 4;
}

.edit__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 29px 34px;
  width: 566px;
  height: 628px;
  border-radius: 12px;
  background-color: #fff;
  z-index: 5;
  margin: 0 auto;
  left: 50%;
  -webkit-transform: translate(-50%, 5%);
  transform: translate(-50%, 5%);

  transition: 300ms;

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    border-radius: 0;

    border-bottom: 1px solid #97ADB6;

    .close-button {
      width: 19px;
      height: 19px;
      
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../Util/Images/x.svg');

      cursor: pointer;
    }

    .info {
      display: flex;

      .photo__section {
        .photoPreview {
          background-color: #C4C4C4;
          width: 117px;
          height: 86px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 3px;
          margin-bottom: 11px;
        }

          .input-photo {
          height: 21px;
          width: 117px;

          bottom: 21px;
          position: relative;
          opacity: 0;
          cursor: pointer;
        }

        .label-photo {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 12px;
          padding-left: 20px;
          @include Mixins.default-font-picker(12px,600,#3D3D3D);

          background-image: url('../../../Util/Images/download-icon.svg');
          background-repeat: no-repeat;
          background-position: 5px center;

          cursor: pointer;
        }  
    

      }

      .info-section {
        margin-left: 18px;
        .place-name {
          @include Mixins.default-font-picker(18px,600,#000000);
        }

        .date {
          @include Mixins.default-font-picker(14px,400,#4F4F4F);
          margin-bottom: 15px;
        }

        .is-active {
          @include Mixins.default-font-picker(16px,400,#4F4F4F);
          
          .checkbox {
            margin: none;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
  
  .header__success {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 10px;
    border-radius: 0;
    margin-bottom: 20px;

    .close-button {
      width: 19px;
      height: 19px;
      
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../Util/Images/x.svg');

      cursor: pointer;
    }
  }
  
  .form {
    display: flex;
    flex-direction: column;

    align-items: center;

    width: 100%;
    flex-grow: 1;


    .label {
      width: 100%;

      @include Mixins.default-font-picker(15px,500,#3D3D3D);
      margin-bottom: 10px;
    }

    .text-input {
      width: 100%;
      height: 44px;
      margin-bottom: 15px;
      padding-left: 16px;
    
      @include Mixins.default-font-picker(15px,400,#3D3D3D);
      outline: none;
      border: 1px solid #D5DDE0;
      border-radius: 10px;
    }

    .submit-button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @include Mixins.default-font-picker(18px, 700, white);

      width: 100%;
      height: 50px;

      outline: none;
      background-color: #E7833B;
      border: none;
  
      border-radius: 34px;
    }
  }
}

.success {
  height: 180px;
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);

  .text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @include Mixins.default-font-picker(24px,600,black);

    .sub-text {
      text-align: center;
      @include Mixins.default-font-picker(16px,400,black);
      margin-top: 15px;
    }
  }
}