@use '../../Util/Styles/Mixins.scss';

.settings-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 0 12px 20px;

  .header {
    height: 80px;
    width: 100%;
    padding-left: 30px;
    display: flex;
    align-items: center;

    background-color: white;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 20px;

    .header__search {
      height: 40px;
      width: 50%;

      border: 1px solid #E2E8F0;
      padding-left: 40px;
      border-radius: 4px;
      outline: none;
      background-repeat: no-repeat;
      background-position: 15px center;
      background-image: url('../../Util/Images/search-icon.svg');

      @include Mixins.default-font-picker(16px,400,#3D3D3D);

      &::-webkit-search-cancel-button {
        position:relative;
        right: 5px;

        -webkit-appearance: none;
        height: 15px;
        width: 15px;

        background-image: url('../../Util/Images/cross.svg');
        background-repeat: no-repeat;
      }
    }
  }

  .list {
    width: 100%;
    flex: 1 1 auto;



    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding: 34px 38px 30px;
      background-color: white;
      border-radius: 5px;

      .title {
        @include Mixins.default-font-picker(20px,400,#3D3D3D);
      }
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(3 ,1fr);
      grid-auto-rows: 178px;
      row-gap: 15px;
      column-gap: 10px;
      
      height: 70vh;
      width: 100%;

      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        background-color: white;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #F2994A;
        border-radius: 5px;
      }
    }
  }
}