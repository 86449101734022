@use '../../../Util/Styles/Mixins.scss';

.blur__background {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translateZ(4);
  z-index: 4;
}

.manager-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 20px 36px;
  width: 600px;
  height: 697px;
  border-radius: 12px;
  background-color: #fff;
  z-index: 5;
  margin: 0 auto;
  left: 50%;
  -webkit-transform: translate(-50%, 5%);
  transform: translate(-50%, 5%);

  .header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 20px;


    .close-button {
      width: 19px;
      height: 19px;
      
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../Util/Images/x.svg');

      cursor: pointer;
    }
  }

  .title {
    @include Mixins.default-font-picker(24px, 700,#4F4F4F);
    margin-bottom: 30px;
  }

  .form {
    display: flex;
    flex-direction: column;

    align-items: center;

    width: 335px;
    flex-grow: 1;

    .input-photo {
      display: none;
    }

    .label-photo {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100px;
      height: 100px;
      background-color: #E7833B;

      border: 2px dashed #3D3D3D;
      border-radius: 10px;

      background-position: center;
      background-size: cover;

      cursor: pointer;

      .plus-icon {
        width: 26px;
        height: 26px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../../Util/Images/plus.svg');
      }
    }

    .label {
      width: 100%;

      @include Mixins.default-font-picker(15px,500,#3D3D3D);
      margin-bottom: 10px;
    }

    .text-input {
      width: 100%;
      height: 44px;
      margin-bottom: 15px;
      padding-left: 16px;
    
      @include Mixins.default-font-picker(15px,400,#3D3D3D);
      outline: none;
      border: 1px solid #D5DDE0;
      border-radius: 10px;
    }

    .access-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      height: 60px;
      column-gap: 5px;
      row-gap: 5px;
      margin-bottom: 20px;

      .access-item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 30px;

        .checkbox {
          height: 24px;
          width: 24px;
          outline: none;
          margin-left: 10px;
        }

        .checkbox-label {
          @include Mixins.default-font-picker(14px, 500, black);
        }
      }
    }

    .submit-button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @include Mixins.default-font-picker(18px, 700, white);

      width: 100%;
      height: 50px;

      outline: none;
      background-color: #E7833B;
      border: none;
  
      border-radius: 34px;
    }
  }
}