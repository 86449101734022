@use '../../Util/Styles/Mixins.scss';

.capture-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 0 12px 20px;

  .header {
    height: 80px;
    width: 100%;
    padding-left: 30px;
    display: flex;
    align-items: center;

    background-color: white;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 20px;

    .header__search {
      height: 40px;
      width: 50%;

      border: 1px solid #E2E8F0;
      padding-left: 40px;
      border-radius: 4px;
      outline: none;
      background-repeat: no-repeat;
      background-position: 15px center;
      background-image: url('../../Util/Images/search-icon.svg');

      @include Mixins.default-font-picker(16px,400,#3D3D3D);

      &::-webkit-search-cancel-button {
        position:relative;
        right: 5px;

        -webkit-appearance: none;
        height: 15px;
        width: 15px;

        background-image: url('../../Util/Images/cross.svg');
        background-repeat: no-repeat;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 1 auto;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding: 34px 38px 30px;
      
      background-color: white;
      border-radius: 5px;

      .title {
        @include Mixins.default-font-picker(20px,400,#3D3D3D);
      }

      .filters {
        display: flex;
        gap: 20px;
      }

      .date-filter {
        width: 180px;
        border: 1px solid #97ADB6;
        box-sizing: border-box;
        border-radius: 4px;
        padding-left: 15px;

        @include Mixins.default-font-picker(16px,400,#97ADB6);

        outline: none;
      }

      .country-select {
        appearance: none;
        height: 40px;
        width: 180px;
        display: flex;
        align-items: center;
        padding-left: 15px;

        @include Mixins.default-font-picker(16px,400,#97ADB6);

        background-repeat: no-repeat;
        background-image: url('../../Util/Images/arrow-down.svg');
        background-position-y: center;
        background-position-x: 150px;

        border: 1px solid #97ADB6;
        box-sizing: border-box;
        border-radius: 4px;

        outline: none;
      }
    }

    .captures {
      display: flex;
      flex-direction: column;
      height: 70vh;
      width: 100%;

      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        background-color: white;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #F2994A;
        border-radius: 5px;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        // flex-wrap: wrap;
        width: 100%;
        height: fit-content;
        flex-shrink: 0;
        margin-bottom: 18px;

        padding: 30px 44px 32px;

        background-color: white;
        border-radius: 5px;

        .location-info {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          height: 100%;

          .date-time {
            @include Mixins.default-font-picker(14px,600, #4F4F4F);
            margin-bottom: 18px;
          }

          .country {
            @include Mixins.default-font-picker(23px,700, #333333);
            margin-bottom: 13px;
          }

          .city {
            @include Mixins.default-font-picker(16px,400, #333333);
            margin-bottom: 8px;
          }

          .additional-info {
            display: flex;
            width: fit-content;
            flex-wrap: wrap;
            height: fit-content;
            gap: 10px;
          }

          .email {
            width: fit-content;
            padding: 6px 16px;
            @include Mixins.default-font-picker(13px,700, white);

            background-color: #778B94;
            border-radius: 10px;
            opacity: 0.5;
          }

          .coordinates {
            width: fit-content;
            padding: 6px 16px;
            @include Mixins.default-font-picker(13px,700, white);

            background-color: #778B94;
            border-radius: 10px;
            opacity: 0.5;
          }
        }

        .info-container {
          display: flex;
          height: 100%;
          // width: 100%;
        }
        
        .parameters-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 240px;
          height: 100%;

          border: 1px solid #E7E7E7;
          border-radius: 10px;

          padding: 21px 24px;
          margin-right: 26px;
          

          .parameter {
            display: flex;
            justify-content: space-between;
            width: 100%;

            @include Mixins.default-font-picker(15px,400,#828282);

            .value {
              @include Mixins.default-font-picker(15px,700,#333333);
            }
          }
        }

        .weather-info {
          display: flex;
          height: 100%;
          width: 335px;
          border: 1px solid #E7E7E7;
          border-radius: 10px;

          padding: 5px 10px;

          &--left {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 60px;
            flex-grow: 1;
            margin-right: 10px;

            .photo {
              width: 64px;
              height: 64px;
              background-repeat: no-repeat;
              background-position: center;
            }

            .text {
              word-wrap: break-word;
              text-align: center;
              @include Mixins.default-font-picker(15px,600, #333333);
            }
          }

          &--right {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .temperature {
              @include Mixins.default-font-picker(24px,700,#4F4F4F);
            }

            .wind, .humidity {
              @include Mixins.default-font-picker(15px,400,#828282);
            }

            .value {
              @include Mixins.default-font-picker(15px,700,black);
            }
          }
        }
      }
    }
  }
}