@use '../../Util/Styles/Mixins.scss';

.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-repeat: no-repeat;
  background-image: url('../../Util/Images/fisherman-1.svg'),
    url('../../Util/Images/fisherman-2.svg'),
    url('../../Util/Images/clouds.svg'),
    url('../../Util/Images/birds.svg');
  background-position: bottom left, bottom 54px left 104px, top right, top 232px right 40px;

  .login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      width: 84px;
      height: 84px;
      background-image: url('../../Util/Images/FishTools-logo.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: 42px;
    }

    .title {
      @include Mixins.default-font-picker(28px,700,#4F4F4F);
      margin-bottom: 32px;
    }

    .login-form {
      display: flex;
      flex-direction: column;
      width: 335px;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 335px;
        background-color: #3D3D3D;
        border: none;
        border-radius: 35px;

        @include Mixins.default-font-picker(18px,700,white);
      }

      .label {
        margin-bottom: 8px;
        @include Mixins.default-font-picker(13px,400,#3D3D3D);
      }

      .text-input {
        width: 100%;
        height: 44px;

        border: 1px solid #D5DDE0;
        border-radius: 10px;

        outline: none;

        @include Mixins.default-font-picker(15px,400,black);
        padding-left: 16px;
        margin-bottom: 16px;
      }
      
      .send-code {
        @include Mixins.default-font-picker(13px,400,#E7833B);
        margin-bottom: 15px;
        text-decoration: underline;
        cursor: pointer;
      }

      .password {
        display: flex;
        overflow: hidden;
        padding-left: 0;

        .password-input {
          flex-grow: 1;
          outline: none;
          border: none;
          height: 100%;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          @include Mixins.default-font-picker(15px,400,black);
          padding-left: 16px;

          transition: 300ms;
        }

        .show-button {
          height: 44px;
          width: 30px;
          background-color: white;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          cursor: pointer;

          background-position: center;
          background-repeat: no-repeat;
          background-image: url('../../Util/Images/eye-off.svg');
        }
      }

      .incorrect {
        border-color: #EB5757;
        color: #EB5757;
      }
    }
  }
}