@use '../../../Util/Styles/Mixins.scss';

.blur__background-form {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: black;
  opacity: 0.15;
  transform: translateZ(4);
  z-index: 4;
}

.message__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 29px 34px;
  width: 566px;
  height: 498px;
  border-radius: 12px;
  background-color: #fff;
  z-index: 5;
  margin: 0 auto;
  left: 50%;
  -webkit-transform: translate(-50%, 5%);
  transform: translate(-50%, 5%);

  transition: 300ms;

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    border-radius: 0;

    .close-button {
      width: 19px;
      height: 19px;
      
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../Util/Images/x.svg');

      cursor: pointer;
    }

    .info {
      display: flex;
    }
  }
  
  .header__success {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 10px;
    border-radius: 0;
    margin-bottom: 20px;

    .close-button {
      width: 19px;
      height: 19px;
      
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../Util/Images/x.svg');

      cursor: pointer;
    }
  }
  
  .form {
    display: flex;
    flex-direction: column;

    align-items: center;

    width: 100%;
    flex-grow: 1;


    .label {
      width: 100%;

      @include Mixins.default-font-picker(13px,400,#97ADB6);
      margin-bottom: 10px;
    }

    .text-input {
      width: 100%;
      height: 44px;
      margin-bottom: 15px;
      padding-left: 16px;
    
      @include Mixins.default-font-picker(15px,400,#3D3D3D);
      outline: none;
      border: 1px solid #D5DDE0;
      border-radius: 10px;
    }

    .react-select-container {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 40px;
    }

    .text-area {
      resize: none;
      height: 170px;
      padding-top: 15px;
    }

    .submit-button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @include Mixins.default-font-picker(18px, 700, white);

      width: 100%;
      height: 50px;

      outline: none;
      background-color: #E7833B;
      border: none;

      cursor: pointer;
  
      border-radius: 34px;
    }
  }
}

.success {
  height: 180px;
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);

  .text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @include Mixins.default-font-picker(24px,600,black);

    .sub-text {
      text-align: center;
      @include Mixins.default-font-picker(16px,400,black);
      margin-top: 15px;
    }
  }
}