@use '../../../Util/Styles/Mixins.scss';

.blur__background {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translateZ(4);
  z-index: 4;
}

.deletion-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 34px 36px;
  width: 750px;
  height: 320px;
  border-radius: 12px;
  background-color: #fff;
  z-index: 5;
  margin: 0 auto;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  transition-duration: 300ms;

  .header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 20px;


    .close-button {
      width: 19px;
      height: 19px;
      
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../Util/Images/x.svg');

      cursor: pointer;
    }
  }

  .title {
    @include Mixins.default-font-picker(24px, 700,#4F4F4F);
    margin-bottom: 20px;
  }

  .text {
    margin-bottom: 30px;
  }

  .buttons {
    display: flex;
    gap: 30px;
    width: 462px;
    height: 50px;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 216px;
      height: 50px;
      @include Mixins.default-font-picker(18px,700,#3D3D3D);
      border-radius: 34px;

      cursor: pointer;
    }

    .confirmation {
      background-color: #3D3D3D;
      color: white;
    }

    .deny {
      border: 1px solid #3D3D3D;
    }
  }
}

.deletion-success {
  height: 250px;
  text-align: center;

  .text {
    text-align: center;
  }
}