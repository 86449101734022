@use '../../../Util/Styles/Mixins.scss';

.settings-item {
  display: flex;
  flex-direction: column;
  height: 178px;
  width: 100%;
  padding: 18px 44px;
  justify-content: space-around;

  background-color: white;
  box-shadow: 0px 6px 4px rgba(222, 219, 219, 0.25);
  border-radius: 5px;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;

    .title {
      @include Mixins.default-font-picker(15px,400,#778B94);
    }

    .button {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;

      cursor: pointer;
    }

    .edit-button {
      background-image: url('../../../Util/Images/edit.svg');
    }

    .send-button {
      background-image: url('../../../Util/Images/bx_bx-check-circle.svg');
    }
  }

  .coefficient {
    @include Mixins.default-font-picker(24px,700,#000000);
  }

  .number-input {
    width: 69%;
    height: 40px;
    margin-bottom: 15px;
    padding-left: 16px;
  
    @include Mixins.default-font-picker(15px,400,#3D3D3D);
    outline: none;
    border: 1px solid #D5DDE0;
    border-radius: 10px;

    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }
}