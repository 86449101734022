@use '../../Util/Styles/Mixins.scss';

.user-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 0 12px 20px;

  .header {
    height: 80px;
    width: 100%;
    padding-left: 30px;
    display: flex;
    align-items: center;

    background-color: white;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 20px;

    .header__search {
      height: 40px;
      width: 50%;

      border: 1px solid #E2E8F0;
      padding-left: 40px;
      border-radius: 4px;
      outline: none;
      background-repeat: no-repeat;
      background-position: 15px center;
      background-image: url('../../Util/Images/search-icon.svg');

      @include Mixins.default-font-picker(16px,400,#3D3D3D);

      &::-webkit-search-cancel-button {
        position:relative;
        right: 5px;

        -webkit-appearance: none;
        height: 15px;
        width: 15px;

        background-image: url('../../Util/Images/cross.svg');
        background-repeat: no-repeat;
      }
    }
  }

  .list {
    width: 100%;
    flex: 1 1 auto;

    background-color: white;
    border-radius: 5px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding: 34px 38px 0;

      &--container {
        display: flex;
        align-items: center
      }


      .title {
        @include Mixins.default-font-picker(20px,400,#3D3D3D);
        margin-right: 20px;
      }

      .users-export {
        width: 24px;
        height: 24px;
        background-size: cover;
        background-image: url('../../Util/Images/import.svg');
      }

      .select-container {
        display: flex;
      }

      .subscription-select {
        appearance: none;
        height: 40px;
        width: 180px;
        display: flex;
        align-items: center;
        padding-left: 15px;

        @include Mixins.default-font-picker(16px,400,#97ADB6);

        background-repeat: no-repeat;
        background-image: url('../../Util/Images/arrow-down.svg');
        background-position-y: center;
        background-position-x: 150px;

        border: 1px solid #97ADB6;
        box-sizing: border-box;
        border-radius: 4px;

        outline: none;
        margin-right: 20px;
      }

      .country-select {
        appearance: none;
        height: 40px;
        width: 180px;
        display: flex;
        align-items: center;
        padding-left: 15px;

        @include Mixins.default-font-picker(16px,400,#97ADB6);

        background-repeat: no-repeat;
        background-image: url('../../Util/Images/arrow-down.svg');
        background-position-y: center;
        background-position-x: 150px;

        border: 1px solid #97ADB6;
        box-sizing: border-box;
        border-radius: 4px;

        outline: none;
      }
    }

    .grid-table {
      height: 65vh;
  
      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 100px;
      }

      &__header {
        height: 40px;
        background-color: #ededed;
        padding: 0px 38px;
      }

      .head {
        display: flex;
        align-items: center;
      }

      .head__email {
        background-repeat: no-repeat;
        // background-image: url('../../Util/Images/sort-icon.svg');
        background-position: 50px center;
      }

      .head__date {
        background-repeat: no-repeat;
        background-image: url('../../Util/Images/sort-icon.svg');
        background-position: 140px center;
        cursor: pointer;
      }

      .table {
        width: 100%;
        height: 100%;

        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 5px;
          background-color: #ededed;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #F2994A;
          border-radius: 5px;
        }

        .grid-item {
          height: 60px;
          padding: 0px 38px;
          border-bottom: 1px solid #97ADB6;

          .user {
            display: flex;
            flex-direction: row;
            align-self: center;

            .user-name {
              @include Mixins.default-font-picker(16px,400,#4F4F4F);
            }
            .user-id {
              @include Mixins.default-font-picker(14px,400,#F2994A);
              margin-right: 20px;
              margin-bottom: -4px;
            }
          }

          .email, .country, .date, .user-type {
            display: flex;
            align-items: center;
            @include Mixins.default-font-picker(14px,400,#4F4F4F);
          }
        }
      }

      &__nav {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}