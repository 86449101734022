@use "../../Util/Styles/Mixins.scss";


.messages-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 0 12px 20px;

  .header {
    height: 80px;
    width: 100%;
    padding-left: 30px;
    display: flex;
    align-items: center;

    background-color: white;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 20px;

    .header__search {
      height: 40px;
      width: 50%;

      border: 1px solid #E2E8F0;
      padding-left: 40px;
      border-radius: 4px;
      outline: none;
      background-repeat: no-repeat;
      background-position: 15px center;
      background-image: url('../../Util/Images/search-icon.svg');

      transition: 300ms;

      @include Mixins.default-font-picker(16px,400,#3D3D3D);

      &::-webkit-search-cancel-button {
        position:relative;
        right: 5px;

        -webkit-appearance: none;
        height: 15px;
        width: 15px;

        background-image: url('../../Util/Images/cross.svg');
        background-repeat: no-repeat;
      }
    }
  }

  .list {
    display: grid;
    flex-direction: column;
    width: 100%;
    flex: 0 1 auto;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding: 34px 38px 30px;
      background-color: white;
      border-radius: 5px;
      background-position-y: center;

      transition: 300ms;

      .title {
        @include Mixins.default-font-picker(20px,400,#3D3D3D);
        transform: translateY(-3px);
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        @include Mixins.default-font-picker(14px,600,white);
        width: 160px;
        height: 42px;
        background-color: #3D3D3D;
        border-radius: 29px;

        cursor: pointer;
      }
    }

    .messages {
      width: 100%;
      height: 73vh;

      overflow-y: auto;
  
      &::-webkit-scrollbar {
        width: 5px;
        background-color: white;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #F2994A;
        border-radius: 5px;
      }

      .message {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        width: 100%;
        height: 194px;
        margin-bottom: 20px;
        background-color: white;
        border-radius: 5px;
        padding: 27px 33px;

        &__info {
          display: flex;
          flex-direction: column;

          .date {
            @include Mixins.default-font-picker(14px,400,#778B94);
            margin-bottom: 10px;
          }

          .theme {
            @include Mixins.default-font-picker(24px,600,#3D3D3D);
            margin-bottom: 20px;
          }

          .place {
            @include Mixins.default-font-picker(11px,500,white);
            background-color: #97ADB6;
            padding: 5px 8px;
            width: fit-content;
            border-radius: 4px;
          }
        }

        &__text {
          display: flex;
          flex-direction: column;

          .title {
            @include Mixins.default-font-picker(13px,400,#97ADB6);
            margin-bottom: 20px;
          }

          .text {
            @include Mixins.default-font-picker(14px,400,#3D3D3D);
            overflow-y: auto;
            height: 103px;
  
            &::-webkit-scrollbar {
              width: 5px;
              background-color: white;
              border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #F2994A;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}