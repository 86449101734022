@use '../../Util/Styles/Mixins.scss';

.manager-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 0 12px 20px;

  .header {
    height: 80px;
    width: 100%;
    padding-left: 30px;
    display: flex;
    align-items: center;

    background-color: white;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 20px;

    .header__search {
      height: 40px;
      width: 50%;

      border: 1px solid #E2E8F0;
      padding-left: 40px;
      border-radius: 4px;
      outline: none;
      background-repeat: no-repeat;
      background-position: 15px center;
      background-image: url('../../Util/Images/search-icon.svg');

      @include Mixins.default-font-picker(16px,400,#3D3D3D);

      &::-webkit-search-cancel-button {
        position:relative;
        right: 5px;

        -webkit-appearance: none;
        height: 15px;
        width: 15px;

        background-image: url('../../Util/Images/cross.svg');
        background-repeat: no-repeat;
      }
    }
  }

  .list {
    width: 100%;
    flex: 1 1 auto;

    background-color: white;
    border-radius: 5px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding: 34px 38px 0;


      .title {
        @include Mixins.default-font-picker(20px,400,#3D3D3D);
      }

      .form-button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 160px;
        height: 42px;

        border-radius: 29px;

        @include Mixins.default-font-picker(13px,600,white);
        background-color: #3D3D3D;

        cursor: pointer;
      }
    }

    .grid-table {
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #F2994A;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #F2994A;
        border-radius: 5px;
      }
  
      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 100px;

        @media (max-width: 1100px) {
          grid-template-columns: 1fr 1fr 1fr 50px;
        }

        @media (max-width: 1000px) {
          grid-template-columns: 1fr 1fr 120px 50px;
        }
      }

      &__header {
        height: 40px;
        background-color: #ededed;
        padding: 0px 38px;
      }

      .head {
        display: flex;
        align-items: center;
      }

      .table {
        width: 100%;
        height: 100%;

        .grid-item {
          height: 80px;
          padding: 0px 38px;
          border-bottom: 1px solid #97ADB6;

          .user {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .email, .phone, .action, .password {
            display: flex;
            align-items: center;
            @include Mixins.default-font-picker(14px,400,#4F4F4F);
          }

          .action {
            cursor: pointer;
          }
        }
      }

      &__nav {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}